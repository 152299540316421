import React, { useContext } from 'react';

import { Link } from 'src/components/Link';
import { ApiContext } from 'src/context/apiContext';

export const ApplicationLinkCell = (cellInfo: any) => {
  const { state, dispatch } = useContext(ApiContext);
  const setNewApplication = (cellInfo: any) => {
    dispatch({
      type: 'setCurrentApplicationId',
      payload: cellInfo.row.original.id,
    });
    dispatch({
      type: 'setCurrentModelVersionId',
      payload: '',
    });
    dispatch({
      type: 'setCurrentModelArtifactId',
      payload: '',
    });
  };

  return (
    <Link
      key={cellInfo.value}
      to={`/accounts/${state.search.curAccountNo}/applications/${cellInfo.row.original.id}`}
      onClick={() => {
        setNewApplication(cellInfo);
      }}
    >
      {cellInfo.value}
    </Link>
  );
};
