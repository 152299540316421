import React, { useState } from 'react';
import { DataTable } from '../DataTable/DataTable';
import { DateColumn } from 'src/components/DateColumn/DateColumn';
import { ColumnSetFilter } from 'src/components/ColumnSetFilter';

interface Props {
  violationData: JobMetricViolation[] | undefined;
  loading: boolean;
}

export const ViolationsTable = ({ violationData, loading }: Props) => {
  const [showRelativeDate, setShowRelativeDate] = useState<boolean>(false);
  const columns = [
    {
      Header: 'Job Name',
      accessor: 'jobId',
      id: 'job-name',
      sortable: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: (props: any) => (
        <div className="tooltip">
          {props.value}
          <div className="tooltip-text">{props.value}</div>
        </div>
      ),
    },
    { Header: 'Job Type', accessor: 'jobType', sortable: true },
    {
      Header: 'Feature',
      accessor: 'feature_name',
      id: 'feature_name',
      sortable: true,
    },
    {
      Header: 'Constraint Check Type',
      accessor: 'constraint_check_type',
      id: 'constraint_check_type',
      sortable: true,
      filterable: true,
      Filter: ColumnSetFilter,
    },
    {
      Header: 'Description',
      accessor: 'description',
      id: 'description',
    },
    DateColumn({
      header: 'Created Date',
      accessor: 'createdDate',
      id: 'createdDate',
      showRelativeDate,
      setShowRelativeDate,
    }),
  ];

  return (
    <DataTable
      options={{
        noDataText: 'No violation data.',
        defaultPageSize: 10,
      }}
      loading={loading}
      data={violationData || []}
      columns={columns}
      heading={<h2>Metrics Violations</h2>}
    />
  );
};
