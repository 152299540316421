import React, { useContext } from 'react';
import { ContentLayout } from 'src/components/ContentLayout/ContentLayout';
import { ApplicationList } from 'src/components/ApplicationList';
import { AccountHeader } from 'src/components/AccountHeader';
import { KatAlert } from '@amzn/katal-react';
import { useParams } from 'react-router-dom';
import { ApiContext } from 'src/context/apiContext';
import { SharedModelsTable } from 'src/components/SharedModelsTable/SharedModelsTable';
import { Link } from 'src/components/Link';

export const AccountPage = () => {
  const { isNewAccount, accountNo } = useParams();
  const { state } = useContext(ApiContext);

  return (
    <ContentLayout heading={<AccountHeader />}>
      <div className="application-list__alert" />
      {isNewAccount && (
        <div className="application-list__alert">
          <KatAlert
            header="Don't Forget"
            cta={
              <Link to={`/accounts/${state.search.curAccountNo}/topics`}>
                Create a Topic Subscription
              </Link>
            }
          >
            You&apos;ll need to create a Topic Subscription in order to send
            events to MLPigeon.
          </KatAlert>
        </div>
      )}
      <div>
        <ApplicationList />
        <SharedModelsTable awsAccount={accountNo} />
      </div>
    </ContentLayout>
  );
};
