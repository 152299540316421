import React from 'react';
import { Link } from 'src/components/Link';
import { Account } from 'src/hooks/useGetAccounts/useGetAccounts';

export const RecentAccounts = ({
  accounts,
  onClick,
}: {
  accounts: Account[] | null;
  onClick?: (account: Account) => void;
}) => (
  <div>
    <p className="recent-accounts__label">Recent Accounts</p>
    <ul className="recent-accounts__accounts">
      {!accounts && (
        <li>
          <p>No recently used accounts.</p>
        </li>
      )}
      {accounts?.map((account) => (
        <li key={account.id}>
          <Link
            to={`/accounts/${account.id}`}
            onClick={() => onClick?.(account)}
            className="recent-accounts__account-link"
          >
            {account.name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
