import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LegendItem,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import {
  buildCategoricalLineChartDataSet,
  buildNumericalLineChartDataSet,
} from 'src/utils/dataQuailtyUtils';
import { Placeholder } from './Placeholder/Placeholder';
import { KatAlert } from '@amzn/katal-react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface DataQualityMetricsChartProps {
  mode: 'numerical' | 'categorical';
  data: {
    features: Set<string>;
    jobs:
      | DataQualityNumericalMetricsDataJob[]
      | DataQualityCategoricalMetricsDataJob[];
  };
  baselineJob?:
    | DataQualityNumericalMetricsDataJob
    | DataQualityCategoricalMetricsDataJob
    | undefined;
  loading: boolean;
  selectedFeatures: string[];
}

export const DataQualityMetricsChart = ({
  mode,
  data,
  baselineJob,
  loading,
  selectedFeatures,
}: DataQualityMetricsChartProps) => {
  const colors = ['#008296', '#e3eced', '#0A6FC2', '#387000'];

  const friendlyStatNames: Record<string, string> = {
    Max: 'Max',
    Min: 'Min',
    Sum: 'Sum',
    Mean: 'Mean',
    stdDev: 'Standard Deviation',
    missingPercentage: 'Percentage of Missing Values',
  };

  const lineChartData =
    mode === 'numerical'
      ? // @ts-expect-error
        buildNumericalLineChartDataSet(data.jobs ?? [], selectedFeatures)
      : // @ts-expect-error
        buildCategoricalLineChartDataSet(data.jobs ?? [], selectedFeatures);

  let baselineChartData: { label: string; data: Record<string, number[]> }[] =
    [];

  if (baselineJob) {
    baselineChartData =
      mode === 'numerical'
        ? buildNumericalLineChartDataSet(
            // @ts-expect-error
            [baselineJob],
            selectedFeatures,
          )
        : // @ts-expect-error
          buildCategoricalLineChartDataSet([baselineJob], selectedFeatures);
  }

  // draw a chart for each specified stat type (min, max, etc). These come from what SageMaker provides
  const statTypes =
    mode === 'numerical'
      ? ['Max', 'Min', 'Mean', 'Sum', 'stdDev', 'missingPercentage']
      : ['missingPercentage'];

  if (selectedFeatures.length > 5) {
    return (
      <KatAlert description="The metrics charts are not rendered when more than 5 features are selected." />
    );
  }

  return (
    <Placeholder
      ready={!loading}
      shapes={
        <>
          <rect width="100%" height="650px" />,
        </>
      }
    >
      <div className="flex gap-12 flex-wrap items-center">
        {statTypes.map((statType, index) => {
          return (
            <div key={index} className="min-w-[31%]">
              <Line
                id="data-quality-metrics-canvas"
                options={{
                  aspectRatio: 1,
                  responsive: true,
                  maintainAspectRatio: true,
                  scales: {
                    x: { title: { display: true, text: 'job' } },
                    y: { title: { display: true, text: 'value' } },
                  },
                  plugins: {
                    legend: {
                      position: 'bottom',
                      display: true,
                      labels: {
                        filter: (item: LegendItem) =>
                          !item.text.includes(' - baseline'),
                      },
                    },
                    title: {
                      display: true,
                      text: `${friendlyStatNames[statType] || ''}`,
                      position: 'top',
                    },
                  },
                }}
                // loops through all the jobs and builds a collection of data for each selected
                // feature. For the baseline job, it copies the first (only) value across all the jobs
                // so that it graphs a line instead of one point
                data={{
                  labels: data.jobs?.map((job) => job.jobName.slice(-5)),
                  datasets: [
                    ...lineChartData.map((dataItem, index) => {
                      const color = colors[index % colors.length]; // get the next color, then loop
                      return {
                        label: dataItem.label,
                        data: dataItem.data[statType],
                        borderColor: color,
                        backgroundColor: color,
                      };
                    }),
                    ...baselineChartData.map((dataItem, index) => ({
                      label: `${dataItem.label} - baseline`,
                      data: new Array(data.jobs.length).fill(
                        dataItem.data[statType]?.[0],
                      ),
                      borderColor: colors[index % colors.length], // should always be the same length, since selectedFeatures is used to generate both
                      backgroundColor: 'transparent',
                      fill: false,
                      borderDash: [7, 7],
                      spanGaps: true,
                      pointRadius: 0, // dont show points, just the line
                    })),
                  ],
                }}
              />
            </div>
          );
        })}
      </div>
    </Placeholder>
  );
};
