import React from 'react';
import { KatLabel } from '@amzn/katal-react';
import { build, ARN } from '@aws-sdk/util-arn-parser';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton/CopyToClipboardButton';
import { truncateString } from 'src/utils/generalUtils/generalUtils';
import { Link } from 'src/components/Link';

export const BedrockLink = ({ arn }: { arn: ARN }) => {
  const linkTextMap: Record<string, string> = {
    'model-customization-job': 'Model Customization Job',
  };

  const href = `https://console.aws.amazon.com/bedrock/home?region=${
    arn.region
  }#/custom-models/item?arn=${build(arn)}`;

  const [resourceType] = arn.resource.split('/');

  return (
    <>
      <KatLabel>{`Bedrock ${linkTextMap[resourceType] || 'Job'} Arn`}</KatLabel>
      <span>
        {truncateString(build(arn), 40)}
        <CopyToClipboardButton
          data={build(arn)}
          icon="content_copy"
          variant="link"
        />
      </span>
      <Link to={href} external>
        Open AWS Bedrock
      </Link>
    </>
  );
};
