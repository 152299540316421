import React, { useState } from 'react';
import { DataTable } from 'src/components/DataTable/DataTable';

import { KatButton } from '@amzn/katal-react';
import { Link } from 'src/components/Link';
import { DateColumn } from 'src/components/DateColumn/DateColumn';
import { useQuery } from '@tanstack/react-query';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest/mlPigeonAPIRequest';

interface Props {
  awsAccount: string | undefined;
}

export const SharedModelsTable = ({ awsAccount }: Props) => {
  const { isLoading, error, data } = useQuery<ModelRegistryModel[], Error>({
    queryKey: ['registry-models', awsAccount],
    queryFn: () =>
      apiGetRequest({
        endpoint: 'sharedModels',
        params: {
          awsAccount: awsAccount || '',
        },
      })
        .then((res) => {
          return res.sharedModels.filter(
            (model: ModelRegistryModel) => !model.deprecated,
          );
        })
        .catch((err) => {
          if (
            err.message ===
            'No objects of type SharedModel found for given criteria'
          ) {
            return [];
          }

          throw new Error(err);
        }),
  });

  const [showCreatedRelativeDate, setShowCreatedRelativeDate] =
    useState<boolean>(true);
  const [showUpdatedRelativeDate, setShowUpdatedRelativeDate] =
    useState<boolean>(true);

  const columns = [
    {
      Header: 'Shared Model Name',
      id: 'modelName',
      accessor: 'modelName',
      sortable: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: (rowInfo: any) => (
        <Link to={`/modelRegistry/model/${rowInfo.row.original.modelId}`}>
          {rowInfo.row.original.modelName}
        </Link>
      ),
    },
    {
      Header: 'Shared Model Id',
      id: 'modelId',
      accessor: 'modelId',
    },
    {
      Header: 'Deprecated',
      id: 'deprecated',
      accessor: 'deprecated',
      Cell: (rowInfo: any) => <span>{rowInfo.value.toString()}</span>,
    },
    DateColumn({
      header: 'Created Date',
      accessor: 'createdDate',
      id: 'created-date',
      showRelativeDate: showCreatedRelativeDate,
      setShowRelativeDate: setShowCreatedRelativeDate,
    }),
    DateColumn({
      header: 'Updated Date',
      accessor: 'updatedDate',
      id: 'updated-date',
      showRelativeDate: showUpdatedRelativeDate,
      setShowRelativeDate: setShowUpdatedRelativeDate,
    }),
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={data || []}
        heading="Shared Models"
        options={{
          noDataText: 'No Shared Models found.',
          defaultSort: {
            id: 'modelName',
            desc: false,
          },
        }}
        loading={isLoading}
        controls={
          <Link to="reserveRegistryName">
            <KatButton>Reserve a Name</KatButton>
          </Link>
        }
      />
      {error && <p className="error-text">{error.message}</p>}
    </>
  );
};
