import React, { ReactElement } from 'react';
import { KatIcon } from '@amzn/katal-react';
import { Link } from 'src/components/Link';
import { SageMakerLink } from 'src/components/SageMakerLink/SageMakerLink';
import { ARN, build } from '@aws-sdk/util-arn-parser';
import { RetryMenu } from 'src/components/RetryMenu/RetryMenu';
import { RetryConfig } from 'src/hooks/useRetryOperator';

import { OperatorInfo } from 'src/components/JobVisualizationNode/OperatorInfo';
import { ConsumedModelLink } from 'src/components/JobVisualizationNode/ConsumedModelLink';
import { SharedModelLink } from 'src/components/JobVisualizationNode/SharedModelLink';
import { StatusLabel } from 'src/components/ApprovalOperatorNode/StatusLabel';
import { BedrockLink } from 'src/components/BedrockLink';

export interface JobVisualizationNodeProps {
  header: string;
  info: {
    id: string;
    operatorName: string;
    modelName?: string;
    modelArn?: ARN;
    region?: string;
  };
  jobType: OperatorJobType;
  status: OperatorJobStatus;
  arnList?: ARN[];
  viewSageMakerGraphHandler?: () => void;
  setRetryConfig: (arg0: RetryConfig) => void;
  setOperatorHelpConfig: (arg: { status: OperatorJobStatus }) => void;
}

export const JobVisualizationNode = ({
  header,
  info,
  status,
  jobType,
  arnList,
  viewSageMakerGraphHandler,
  setRetryConfig,
  setOperatorHelpConfig,
}: JobVisualizationNodeProps): ReactElement => {
  const jobTypeMap: Record<OperatorJobStatus, string> = {
    NOTSTARTED: 'Not Started',
    STARTING: 'Starting',
    PENDING: 'Pending',
    SUCCEEDED: 'Succeeded',
    FAILED: 'Failed',
  };
  const friendlyStatus: string = jobTypeMap[status];

  return (
    <div className="job-visualization-node">
      <div className="job-visualization-node__header">
        <h3>{header}</h3>
        <div className="job-visualization-node__status-container">
          <StatusLabel loading={false} label={friendlyStatus.toLowerCase()} />
          {status !== 'SUCCEEDED' && (
            <button
              className="icon-button"
              onClick={() => setOperatorHelpConfig({ status })}
              title="Troubleshooting"
            >
              <KatIcon size="small" name="help" />
            </button>
          )}
        </div>
      </div>
      <OperatorInfo
        operatorName={info.operatorName}
        operatorId={info.id}
        region={
          info.region && info.region !== 'REGIONLESS' ? info.region : undefined
        }
      />
      <div className="job-visualization-node__links">
        {arnList?.map((arn) => {
          const [resourceType] = arn.resource.split('/');

          // SageMaker has no UI for monitoring schedules, so we don't make a link
          if (
            resourceType !== 'monitoring-schedule' &&
            resourceType !== 'pipeline'
          )
            return jobType === 'MODELCUSTOMIZATIONJOB' ? (
              <BedrockLink key={build(arn)} arn={arn} />
            ) : (
              <SageMakerLink key={build(arn)} arn={arn} />
            );
        })}
        <Link to={`operatorHistory?operatorName=${info.operatorName}`}>
          View History
        </Link>
        {jobType === 'SHAREMODELJOB' && (
          <SharedModelLink
            shareJobId={info.id}
            succeeded={status === 'SUCCEEDED'}
          />
        )}
        {jobType === 'SHAREDMODELCONSUMEJOB' && (
          <ConsumedModelLink consumeJobId={info.id} />
        )}
        {jobType === 'SAGEMAKERPIPELINEEXECUTION' && (
          <Link
            to="#nogo"
            onClick={() => {
              viewSageMakerGraphHandler?.();
            }}
          >
            View SageMaker Pipeline Graph
          </Link>
        )}
      </div>
      <div className="job-visualization-node__buttons">
        {jobType !== 'FEEDBACKLOOPJOB' && (
          <>
            {status !== 'NOTSTARTED' && (
              <Link to={`../logs?operatorName=${info.operatorName}`} newTab>
                View Logs
              </Link>
            )}

            {status === 'FAILED' && (
              <RetryMenu
                nodeData={{ id: info.id, operatorName: info.operatorName }}
                setConfig={setRetryConfig}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
