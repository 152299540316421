import React, { useEffect, useState } from 'react';
import { Link } from 'src/components/Link';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest/mlPigeonAPIRequest';

interface Props {
  consumeJobId: string;
}
export const ConsumedModelLink = ({ consumeJobId }: Props) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (!consumeJobId) return;

    const callForIds = async () => {
      try {
        const {
          consumedModelVariationId,
          consumedModelVariationVersionId,
          region,
        } = await apiGetRequest({
          endpoint: `/sharedModelConsumeJobs/${consumeJobId}`,
        });

        const data = await apiGetRequest({
          endpoint: `sharedModelVariations/${consumedModelVariationId}`,
        });
        setData({ ...data, consumedModelVariationVersionId, region });
      } catch (e) {
        console.error(e);
      }
    };

    callForIds();
  }, [consumeJobId]);

  if (
    data?.modelId &&
    data?.modelVariationId &&
    data?.consumedModelVariationVersionId &&
    data?.region
  ) {
    return (
      <>
        <Link
          to={`/modelRegistry/model/${data.modelId}/variation/${data.modelVariationId}`}
        >
          View Consumed Model in Model Registry
        </Link>
        <span className="text-size-sm text-secondary">
          (version {data.consumedModelVariationVersionId} - {data.region})
        </span>
      </>
    );
  }

  return <p className="text-disabled">View Consumed Model in Model Registry</p>;
};
