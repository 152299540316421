import { useState } from 'react';
import { KatDropdown } from '@amzn/katal-react';
import { FeatureSelect } from 'src/components/FeatureSelect';
import { DataQualityMetricsChart } from 'src/components/DataQualityMetricsChart';
import { DataTable } from 'src/components/DataTable/DataTable';
import { CellProps, Column } from 'react-table';
import React from 'react';
import { MetricValueCell } from 'src/components/table-cells/MetricValueCell/MetricValueCell';
import { DateColumn } from 'src/components/DateColumn/DateColumn';

interface FeatureMetricsProps {
  loading: boolean;
  mode: 'numerical' | 'categorical';
  baselineJob?: DataQualityNumericalMetricsDataJob;
  setBaselineJob?: (job: DataQualityNumericalMetricsDataJob) => void;
  data: {
    features: Set<string>;
    jobs:
      | DataQualityNumericalMetricsDataJob[]
      | DataQualityCategoricalMetricsDataJob[];
  } | null;
  selectedFeatures: string[];
  setSelectedFeatures: (features: string[]) => void;
}

export const FeatureMetrics = ({
  loading,
  mode,
  baselineJob,
  setBaselineJob,
  data,
  selectedFeatures,
  setSelectedFeatures,
}: FeatureMetricsProps) => {
  const [selectedMetric, setSelectedMetric] = useState(
    mode === 'numerical' ? 'min' : 'missingPercentage',
  );

  const [showRelativeDate, setShowRelativeDate] = useState<boolean>(false);

  if (!data?.features || data.features.size === 0) {
    return <p className="py-20">No Features of this type detected.</p>;
  }

  const tableColumns: Column[] = [
    ...Array.from(selectedFeatures).reduce<Column[]>((acc, curr) => {
      acc.push({
        Header: curr,
        id: curr,
        // @ts-expect-error
        accessor: [curr]?.[selectedMetric],
        Cell: (rowInfo: CellProps<Record<string, any>>) => {
          return selectedMetric === 'valueCount' ? (
            <div>
              {rowInfo.row.original[curr]?.[selectedMetric].map(
                (item: any, i: number) => <p key={i}>{item}</p>,
              )}
            </div>
          ) : (
            <MetricValueCell
              value={rowInfo.row.original[curr]?.[selectedMetric]}
            />
          );
        },
      });
      return acc;
    }, []),
  ];

  const tableOptions =
    mode === 'numerical'
      ? [
          { name: 'min', value: 'min' },
          { name: 'mean', value: 'mean' },
          { name: 'sum', value: 'sum' },
          { name: 'max', value: 'max' },
          { name: 'Standard Deviation', value: 'stdDev' },
          { name: 'Missing Percentage', value: 'missingPercentage' },
        ]
      : [
          { name: 'Missing Percentage', value: 'missingPercentage' },
          { name: 'Value Counts', value: 'valueCount' },
        ];

  const createdDateColumn = DateColumn({
    header: 'Created Date',
    accessor: 'createdDate',
    id: 'created-date',
    showRelativeDate,
    setShowRelativeDate,
  });

  return (
    <div>
      <div className="mt-10 mb-5 flex justify-between items-end flex-wrap gap-2">
        {setBaselineJob && (
          <div className="relative">
            <div className="min-w-80">
              <KatDropdown
                label="Baseline Job"
                tooltipText="The selected baseline job will have it's feature's metrics graphed as dashed lines for easy comparison.
                The job will also be highlighted in the table below."
                searchable={true}
                value={baselineJob?.jobName ?? ''}
                onChange={(e) => {
                  setBaselineJob(
                    // @ts-expect-error
                    data?.jobs.find((job) => job.jobName === e.detail.value) ??
                      null,
                  );
                }}
                placeholder="Select a job"
                options={data?.jobs.map((job) => ({
                  name: `${job.jobName} - ${job.jobType}`,
                  value: job.jobName,
                }))}
              />
            </div>
          </div>
        )}
        <div className="flex gap-2 items-center">
          <span className="font-normal mb-0">
            <span className="font-bold">{selectedFeatures.length}</span> /{' '}
            <span className="font-bold">{data.features.size}</span> features
            selected
          </span>
          <FeatureSelect
            setSelectedFeatures={setSelectedFeatures}
            featureList={Array.from(data?.features ?? [])}
          />
        </div>
      </div>

      <DataQualityMetricsChart
        baselineJob={baselineJob ?? undefined}
        mode={mode}
        data={data ?? { features: new Set(), jobs: [] }}
        loading={loading}
        selectedFeatures={selectedFeatures}
      />
      <div className="mt-16">
        <DataTable
          highLightedRow={{
            columnId: 'jobName',
            value: baselineJob?.jobName ?? '',
          }}
          heading={
            <>
              <h3 className="mb-0">Metrics by Job</h3>

              <div className="flex gap-2 items-end">
                <div className="min-w-32 relative">
                  <KatDropdown
                    label="Metric"
                    tooltipText="Select which data quality metric to display for each job and feature."
                    value={selectedMetric}
                    options={tableOptions}
                    onChange={(e) => setSelectedMetric(e.detail.value)}
                  />
                </div>
              </div>
            </>
          }
          controls={
            <div className="flex gap-4 items-end">
              <span className="font-normal mb-2 ml-4">
                <span className="font-bold">{selectedFeatures.length}</span> /{' '}
                <span className="font-bold">{data.features.size}</span> features
                selected
              </span>
              <FeatureSelect
                setSelectedFeatures={setSelectedFeatures}
                featureList={Array.from(data?.features ?? [])}
              />
            </div>
          }
          columns={[
            {
              Header: 'Job Name',
              accessor: 'jobName',
            },
            { Header: 'Job Type', accessor: 'jobType', sortable: true },
            createdDateColumn,
            ...tableColumns,
          ]}
          data={data?.jobs ?? []}
        />
      </div>
    </div>
  );
};
