import { KatAlert, KatSpinner, KatTab, KatTabs } from '@amzn/katal-react';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { BreadcrumbNavigation } from 'src/components/BreadcrumbNavigation/BreadcrumbNavigation';
import { ContentLayout } from 'src/components/ContentLayout/ContentLayout';
import { DateRangePicker } from 'src/components/DateRangePicker';
import { ViolationsTable } from 'src/components/ViolationsTable/ViolationsTable';
import { ApiContext } from 'src/context/apiContext';
import { processMetricsData } from 'src/utils/dataQuailtyUtils';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest/mlPigeonAPIRequest';
import {
  convertDateToEpochTimeInSeconds,
  getDateRangeFromToday,
} from 'src/utils/timeUtils/timeUtils';
import { FeatureMetrics } from 'src/components/FeatureMetrics';
import { ErrorsByJobTable } from 'src/components/ErrorsByJobTable';
import { Link } from 'src/components/Link';

export const DataQualityMetricsPage = () => {
  const { state } = useContext(ApiContext);

  const modelArtifactId = state.search.curModelArtifactId;

  const currentApplicationId = state.search.curApplicationId;
  const applicationDetails = state.applications.data.find(
    (app) => app['id'] === currentApplicationId,
  );
  const currentModelVersionId = state.search.curModelVersionId;
  const modelVersionDetails = state.modelVersions.data.find(
    (mv) => mv['id'] === currentModelVersionId,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { trainingJobId, monitoringScheduleId } =
    useParams<Record<string, string>>();
  const params = new URLSearchParams(searchParams);
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');
  const initDateRange =
    startDate && endDate
      ? { startDate: new Date(startDate), endDate: new Date(endDate) }
      : getDateRangeFromToday(7);
  const [timeFilter, setTimeFilter] = useState(initDateRange);
  const [selectedNumericalFeatures, setSelectedNumericalFeatures] = useState<
    string[]
  >([]);
  const [selectedCategoricalFeatures, setSelectedCategoricalFeatures] =
    useState<string[]>([]);
  const [numericalBaselineJob, setNumericalBaselineJob] =
    useState<DataQualityNumericalMetricsDataJob | null>(null);
  const [categoricalBaselineJob, setCategoricalBaselineJob] =
    useState<DataQualityCategoricalMetricsDataJob | null>(null);
  const [numericalData, setNumericalData] = useState<{
    features: Set<string>;
    jobs: DataQualityNumericalMetricsDataJob[];
  } | null>(null);
  const [categoricalData, setCategoricalData] = useState<{
    features: Set<string>;
    jobs: DataQualityCategoricalMetricsDataJob[];
  } | null>(null);

  const modelMetricsQuery = useQuery<ModelMetric[], Error>({
    queryKey: [
      'model-metrics',
      modelArtifactId,
      trainingJobId,
      monitoringScheduleId,
      timeFilter,
    ],
    queryFn: () => {
      const startOfDay = timeFilter.startDate;
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = timeFilter.endDate;
      endOfDay.setHours(23, 59, 59, 999);
      return apiGetRequest({
        endpoint: 'modelMetric',
        params: {
          modelArtifactId: trainingJobId ? '' : modelArtifactId,
          trainingJobId: trainingJobId ?? monitoringScheduleId ?? '',
          metricsType: 'DATAQUALITY',
          startTime: convertDateToEpochTimeInSeconds(startOfDay).toString(),
          endTime: convertDateToEpochTimeInSeconds(endOfDay).toString(),
        },
      }).then((data) => {
        const { numericalData, categoricalData } = processMetricsData(data);
        setNumericalData(numericalData);
        setCategoricalData(categoricalData);
        setSelectedNumericalFeatures(
          Array.from(numericalData.features).slice(0, 5),
        );
        if (trainingJobId) {
          setNumericalBaselineJob(
            numericalData?.jobs?.find((job) => job.jobName === trainingJobId) ||
              null,
          );
          setCategoricalBaselineJob(
            categoricalData?.jobs?.find(
              (job) => job.jobName === trainingJobId,
            ) || null,
          );
        }
        setSelectedCategoricalFeatures(
          Array.from(categoricalData.features).slice(0, 5),
        );
        return data;
      });
    },
  });

  return (
    <ContentLayout
      heading={
        <>
          <BreadcrumbNavigation
            links={[
              {
                display: `Account (${state.search.curAccountNo})`,
                href: `/accounts/${state.search.curAccountNo}`,
              },
              {
                display: applicationDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}`,
              },
              {
                display: modelVersionDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}`,
              },
              {
                display: `Data Quality Metrics (${modelArtifactId})`,
              },
            ]}
          />
          <h1>Data Quality Metrics</h1>
        </>
      }
    >
      <DateRangePicker
        initDateRange={initDateRange}
        changeDateRange={setTimeFilter}
        setSearchParams={setSearchParams}
        label="Job Run"
      />

      <p>
        {!modelMetricsQuery.isLoading ? (
          `${modelMetricsQuery.data?.length || '0'} job(s) found.`
        ) : (
          <KatSpinner size="small" />
        )}
      </p>

      {(trainingJobId || monitoringScheduleId) && (
        <div className="my-8">
          <KatAlert
            variant="info"
            header="Job Filter"
            description={`The current view is restricting the jobs in the time range to jobs that are related to: ${
              trainingJobId
                ? `${trainingJobId} - TRAINING JOB`
                : `${monitoringScheduleId} - MONITORING SCHEDULE`
            }`}
          >
            <div slot="cta">
              <Link
                to={`/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}/modelArtifacts/${modelArtifactId}/dataQualityMetrics`}
              >
                Clear Job Filter
              </Link>
            </div>
          </KatAlert>
        </div>
      )}

      {modelMetricsQuery.error && (
        <KatAlert
          variant="danger"
          title={'Unable to fetch data'}
          description={`${
            modelMetricsQuery?.error?.message || ''
          } If the data fetch has failed, it's possible that the amount of data
          returned exceeds the Lambda payload limit. In that case, you can try
          to reduce the time range, using the controls above. `}
        />
      )}

      <ErrorsByJobTable
        errorMessages={modelMetricsQuery.data?.reduce<
          { jobId: string; jobType: string; errorMessages: string[] }[]
        >((prev, curr) => {
          if (curr.errorMessages) {
            prev.push({
              jobId: curr.jobId,
              jobType: curr.jobType,
              errorMessages: curr.errorMessages || [],
            });
          }
          return prev;
        }, [])}
      />

      <div className="mb-20">
        <ViolationsTable
          loading={modelMetricsQuery.isLoading}
          violationData={modelMetricsQuery.data?.reduce<JobMetricViolation[]>(
            (prev, curr) => {
              if (curr.constraintViolations) {
                const violations: MetricViolation[] = JSON.parse(
                  curr.constraintViolations,
                ).violations;
                violations?.forEach((violation) => {
                  prev.push({
                    jobId: curr.jobId,
                    jobType: curr.jobType,
                    createdDate: curr.createdDate,
                    feature_name: violation.feature_name,
                    constraint_check_type: violation.constraint_check_type,
                    description: violation.description,
                  });
                });
              }

              return prev;
            },
            [],
          )}
        />
      </div>

      <h2>Feature Metrics</h2>

      <KatTabs>
        <KatTab label="Numerical Statistics" tabId={'numerical-tab'}>
          <FeatureMetrics
            loading={modelMetricsQuery.isLoading}
            mode="numerical"
            // @ts-expect-error
            baselineJob={numericalBaselineJob}
            setBaselineJob={setNumericalBaselineJob}
            data={numericalData}
            selectedFeatures={selectedNumericalFeatures}
            setSelectedFeatures={setSelectedNumericalFeatures}
          />
        </KatTab>
        <KatTab label="Categorical Statistics" tabId={'categorical-tab'}>
          <FeatureMetrics
            loading={modelMetricsQuery.isLoading}
            mode="categorical"
            // @ts-expect-error
            baselineJob={categoricalBaselineJob}
            // @ts-expect-error
            setBaselineJob={setCategoricalBaselineJob}
            data={categoricalData}
            selectedFeatures={selectedCategoricalFeatures}
            setSelectedFeatures={setSelectedCategoricalFeatures}
          />
        </KatTab>
      </KatTabs>
    </ContentLayout>
  );
};
