import React, { useState, useEffect } from 'react';
import * as KatalMetrics from '@amzn/katal-metrics';
import { KatIcon } from '@amzn/katal-react';
import { Link } from 'src/components/Link';

export const SubNavMenu = ({
  menuText,
  iconName = 'dehaze',
  iconSize = 'medium',
  links,
  metricsPublisher,
}: {
  menuText?: string;
  iconName?: KatIcon.Name;
  iconSize?: KatIcon.Size;
  links: {
    display: string;
    detail?: string;
    href: string;
    external?: boolean;
  }[];
  metricsPublisher?: KatalMetrics.Publisher;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const actionMetricsPublisher = metricsPublisher
    ? metricsPublisher.newChildActionPublisherForMethod('HelpMenu')
    : null;

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsOpen(false);
    });
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') setIsOpen(false);
    });
    window.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      if (
        // @ts-ignore
        !target?.matches(['.subnav-menu', '.subnav-menu *'])
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  const linkClickHandler = (name: string) => {
    actionMetricsPublisher?.publishCounterMonitor(name, 1);
  };

  return (
    <>
      <div className="subnav-menu relative">
        <button
          className="subnav-menu__display"
          onClick={() => {
            !isOpen && linkClickHandler('help-menu-open');
            setIsOpen(!isOpen);
          }}
          aria-expanded={isOpen}
        >
          <span className="subnav-menu__menu-text">{menuText}</span>
          <KatIcon name={iconName} size={iconSize} />
        </button>
        {isOpen && (
          <div className="bg-white shadow-md absolute right-0 min-w-[250px] z-10 subnav-menu__menu">
            <ul>
              {links.map((link) => (
                <li key={link.href} className="px-4 py-2 hover:bg-gray-50">
                  <Link
                    to={link.href}
                    onClick={() => {
                      linkClickHandler(
                        `${link.display.replace(/ /g, '-')}-click`,
                      );
                      setIsOpen(false);
                    }}
                    external={link.external}
                  >
                    {link.display}
                  </Link>
                  {link.detail && <p>{link.detail}</p>}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
